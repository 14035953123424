<template>
    <div class="content home">
        <div class="container">
            <div class="flex">
                <div class="flex-col flex-col-xs--12 flex-col-offset-md-2 flex-col-md--8">
                    <h1 v-html="content.home.title"></h1>
                    <p v-html="content.home.text" class="text--large"></p>
                </div>
            </div>
            <div class="flex">
                <div v-for="item in content.home.teasers" v-bind:key="item.title" class="flex-col flex-col-xs--12 flex-col-md--3">
                    <router-link :to="{path: item.url}" class="teaser">
                        <div class="teaser__content" :style="{ backgroundImage: 'url(' + getImgUrl(item.img) + ')' }">
                            <span class="teaser__icon-circle icon--arrow">
                                <font-awesome-icon :icon="['far', 'long-arrow-alt-right']" />
                            </span>
                        </div>
                        <h2 v-html="item.title"></h2>
                    </router-link>
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';
import json from '../json/global.json';
// import fontawesome from "@fortawesome/fontawesome-pro";
import { library } from '@fortawesome/fontawesome-svg-core';
// # Note we are using the Pro style here
import { faLongArrowAltRight } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

library.add(faLongArrowAltRight);

@Options({
    components: {
        FontAwesomeIcon
    },
    data () {
        return {
            content: json,
            publicPath: process.env.BASE_URL
        };
    },
    methods: {
        getImgUrl (pic: string) {
            return this.publicPath + 'assets/' + pic;
        }
    }
})
export default class Home extends Vue {}
</script>
