<template>
    <figure class="skyline">
        <img :src="`${publicPath}assets/images/skyline.svg`" alt="">
    </figure>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';

@Options({
    data () {
        return {
            publicPath: process.env.BASE_URL
        }
    }
})
export default class Skyline extends Vue {
}
</script>
