<template>
    <header>
        <div class="container">
            <div class="flex flex--center">
                <div class="flex-col flex-col-xs--2">
                    <a v-if="back" @click="goBack()" class="header__link">
                        <span class="icon--overview">
                            <font-awesome-icon :icon="['fas', 'th-large']" />
                        </span>
                        overzicht
                    </a>
                </div>
                <div class="flex-col flex-col-xs--8">
                    <router-link to="/" class="header__link-logo">
                        <img :src="`${publicPath}assets/images/atlas.svg`" alt="" class="header__logo">
                    </router-link>
                </div>
            </div>
        </div>
    </header>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faThLarge } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

library.add(faThLarge);

@Options({
    components: {
        FontAwesomeIcon
    },
    data () {
        return {
            publicPath: process.env.BASE_URL
        }
    },
    props: {
        back: Boolean
    },
    methods: {
        goBack () {
            this.$router.push('/');
        }
    }
})
export default class Nav extends Vue {
    back!: boolean
}
</script>
