<template>
    <div class="wrapper">
        <main id="main">
            <!--{{this.$route}}-->
            <Nav :back="this.$route.path !== '/'"/>
            <router-view/>
            <Skyline/>
        </main>
    </div>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';
import Nav from '@/components/Nav.vue';
import Skyline from '@/components/Skyline.vue';

@Options({
    components: {
        Nav,
        Skyline
    }
})

export default class App extends Vue {}

</script>

<style lang="scss">
    #app{}
</style>
