<template>
    <div class="content no-padding-top padding-bottom" v-bind:class="{ overflow: setOverflow }">
        <div v-on:click="pausePlay" class="js-play-video video-hero">
            <span class="video-icon">
                <font-awesome-icon :icon="['fab', 'youtube']" />
            </span>
            <video crossOrigin="anonymous" playsinline v-if="getVideoUrl(this.detail.video)" :src="getVideoUrl(this.detail.video)" :poster="getImageUrl(this.detail.img)" type="video/mp4">
                <!--<source :src="getImageUrl(this.detail.img)">-->
            </video>
            <img v-if="this.detail.img" :src="getImageUrl(this.detail.img)" alt="">
        </div>
        <div class="js-check-height container">
            <div class="flex">
                <div class="flex-col flex-col-xs--12 flex-col-offset-md-1 flex-col-md--10">
                    <h1 v-if="this.detail.name !== undefined && this.detail.place !== undefined">
                        <span>{{this.detail.name}}</span> -
                        {{this.detail.place}}
                    </h1>
                    <h1 v-else v-html="this.detail.title"></h1>
                    <p class="text--large" v-if="this.detail.text" v-html="this.detail.text">
                    </p>
                </div>
            </div>
        </div>
        <a v-if="canBack" @click="goBack()" class="link-back">
            <span class="icon--overview">
                <font-awesome-icon :icon="['fas', 'th']" />
            </span>
            {{content.witnesses.back}}
        </a>
    </div>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';
import json from '../json/global.json';
// import fontawesome from "@fortawesome/fontawesome-pro";
import { library } from '@fortawesome/fontawesome-svg-core';
// # Note we are using the Pro style here
import { faYoutube } from '@fortawesome/free-brands-svg-icons';
import { faTh } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

library.add(faTh, faYoutube);

@Options({
    components: {
        FontAwesomeIcon
    },
    data () {
        return {
            content: json,
            publicPath: process.env.BASE_URL,
            canBack: window.history.state.back !== '/',
            setOverflow: false
        };
    },
    methods: {
        getVideoUrl (video: string) {
            return this.publicPath + 'assets/videos/' + video;
        },
        getImageUrl (pic: string) {
            return this.publicPath + 'assets/' + pic;
        },
        goBack () {
            console.log(window.history);
            window.history.length > 1 ? this.$router.go(-1) : this.$router.push('/');
        },
        pausePlay (event: any) {
            const target = event.target;
            const video = document.getElementsByTagName('video')[0];
            // target.classList.toggle('playing');
            this.handlePlayButton(video, target);
            console.log('click', event, video);
        },
        handlePlayButton (vid: any, tar: any) {
            console.log('handle', vid.paused);
            if (vid.paused) {
                this.playVideo(vid, tar);
            } else {
                vid.pause();
                tar.classList.remove('playing');
            }
        },
        async playVideo (vid: any, tar: any) {
            try {
                await vid.play();
                tar.classList.add('playing');
            } catch (err) {
                console.log('error', err);
                tar.classList.remove('playing');
            }
        },
        checkHeight () {
            const container = document.getElementsByClassName('js-check-height')[0];
            if (container !== undefined) {
                console.log(container.getBoundingClientRect());
                if (container.getBoundingClientRect().height >= 200) {
                    this.setOverflow = true;
                }
            }
        }
    },
    props: {
        detail: Object
    },
    created (): void {
        console.log(window.history);
    },
    mounted (): void {
        console.log('ready');
        this.checkHeight();
    }
})
export default class VideoLayout extends Vue {}
</script>
