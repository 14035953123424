
import { Options, Vue } from 'vue-class-component';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faThLarge } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

library.add(faThLarge);

@Options({
    components: {
        FontAwesomeIcon
    },
    data () {
        return {
            publicPath: process.env.BASE_URL
        }
    },
    props: {
        back: Boolean
    },
    methods: {
        goBack () {
            this.$router.push('/');
        }
    }
})
export default class Nav extends Vue {
    back!: boolean
}
