import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';
import Home from '../views/Home.vue';
import Detail from '../views/Detail.vue';
import Stories from '../views/Stories.vue';

const routes: Array<RouteRecordRaw> = [
    {
        path: '/',
        name: 'Ontdek Stadsklap',
        component: Home
    },
    {
        path: '/verhalen',
        name: 'verhalen',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: Stories
    },
    {
        path: '/verhalen/:name',
        name: 'verhalen detail',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: Detail
    },
    {
        path: '/favorieten',
        name: 'Lievelings woorden',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/Favorites.vue')
    },
    {
        path: '/favorieten/:name',
        name: 'favorieten detail',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: Detail
    },
    {
        path: '/wandel-route',
        name: 'Wandel route',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/Minister.vue')
    },
    {
        path: '/doe-mee',
        name: 'Doe mee',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/Action.vue')
    }
];

const scrollBehavior = (to: any, from: any, savedPosition: any) => {
    return new Promise<any>((resolve, reject) => {
        setTimeout(() => {
            resolve({ x: 0, y: 0 })
        }, 500)
    });
}

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
    scrollBehavior
});

export default router;
