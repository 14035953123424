
import { Options, Vue } from 'vue-class-component';
import Nav from '@/components/Nav.vue';
import Skyline from '@/components/Skyline.vue';

@Options({
    components: {
        Nav,
        Skyline
    }
})

export default class App extends Vue {}

