
import { Options, Vue } from 'vue-class-component';
import json from '../json/global.json';
// import fontawesome from "@fortawesome/fontawesome-pro";
import { library } from '@fortawesome/fontawesome-svg-core';
// # Note we are using the Pro style here
import { faTh } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import VideoLayout from '../components/VideoLayout.vue';
import PhotoLayout from '../components/PhotoLayout.vue';

library.add(faTh);

@Options({
    components: {
        FontAwesomeIcon,
        VideoLayout,
        PhotoLayout
    },
    data () {
        return {
            content: json,
            publicPath: process.env.BASE_URL
        };
    },
    methods: {
        goBack () {
            window.history.length > 1 ? this.$router.go(-1) : this.$router.push('/');
        }
    }
})
export default class Detail extends Vue {}
