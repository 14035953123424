
import { Options, Vue } from 'vue-class-component';
import json from '../json/global.json';
// import fontawesome from "@fortawesome/fontawesome-pro";
import { library } from '@fortawesome/fontawesome-svg-core';
// # Note we are using the Pro style here
import { faTh } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

library.add(faTh);

@Options({
    components: {
        FontAwesomeIcon
    },
    data () {
        return {
            content: json,
            publicPath: process.env.BASE_URL
        };
    },
    methods: {
        getImageUrl (pic: string) {
            return this.publicPath + 'assets/' + pic;
        },
        goBack () {
            window.history.length > 1 ? this.$router.go(-1) : this.$router.push('/');
        }
    },
    props: {
        detail: Object
    }
})
export default class PhotoLayout extends Vue {}
