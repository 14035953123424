
import { Options, Vue } from 'vue-class-component';

@Options({
    data () {
        return {
            publicPath: process.env.BASE_URL
        }
    }
})
export default class Skyline extends Vue {
}
