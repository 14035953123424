
import { Options, Vue } from 'vue-class-component';
import json from '../json/global.json';
// import fontawesome from "@fortawesome/fontawesome-pro";
import { library } from '@fortawesome/fontawesome-svg-core';
// # Note we are using the Pro style here
import { faYoutube } from '@fortawesome/free-brands-svg-icons';
import { faTh } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

library.add(faTh, faYoutube);

@Options({
    components: {
        FontAwesomeIcon
    },
    data () {
        return {
            content: json,
            publicPath: process.env.BASE_URL,
            canBack: window.history.state.back !== '/',
            setOverflow: false
        };
    },
    methods: {
        getVideoUrl (video: string) {
            return this.publicPath + 'assets/videos/' + video;
        },
        getImageUrl (pic: string) {
            return this.publicPath + 'assets/' + pic;
        },
        goBack () {
            console.log(window.history);
            window.history.length > 1 ? this.$router.go(-1) : this.$router.push('/');
        },
        pausePlay (event: any) {
            const target = event.target;
            const video = document.getElementsByTagName('video')[0];
            // target.classList.toggle('playing');
            this.handlePlayButton(video, target);
            console.log('click', event, video);
        },
        handlePlayButton (vid: any, tar: any) {
            console.log('handle', vid.paused);
            if (vid.paused) {
                this.playVideo(vid, tar);
            } else {
                vid.pause();
                tar.classList.remove('playing');
            }
        },
        async playVideo (vid: any, tar: any) {
            try {
                await vid.play();
                tar.classList.add('playing');
            } catch (err) {
                console.log('error', err);
                tar.classList.remove('playing');
            }
        },
        checkHeight () {
            const container = document.getElementsByClassName('js-check-height')[0];
            if (container !== undefined) {
                console.log(container.getBoundingClientRect());
                if (container.getBoundingClientRect().height >= 200) {
                    this.setOverflow = true;
                }
            }
        }
    },
    props: {
        detail: Object
    },
    created (): void {
        console.log(window.history);
    },
    mounted (): void {
        console.log('ready');
        this.checkHeight();
    }
})
export default class VideoLayout extends Vue {}
