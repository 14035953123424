<template>
    <div class="content content--img no-padding-top">
        <div class="container">
            <div class="flex justify-center">
                <div class="flex-col flex-col-xs--12 flex-col-md--7">
                    <div class="img-wrap">
                        <img class="full-height" :src="getImageUrl(this.detail.img)" alt="">
                    </div>
                </div>
                <div class="flex-col flex-col-xs--12 flex-col-md--5">
                    <div class="content__inner">
                        <div class="content__scroll">
                            <template v-if="this.detail.word !== undefined && this.detail.word !== ''">
                                <h1 v-if="this.detail.word !== undefined"><span>{{this.detail.word}}</span></h1>
                                <h1 v-else v-html="this.detail.title"></h1>
                            </template>
                            <template v-else>
                                <h1>
                                    <span>{{ this.detail.name }}</span>
                                    <template v-if="this.detail.place !== undefined && this.detail.place !== ''">
                                        - {{ this.detail.place }}
                                    </template>
                                </h1>
                            </template>
                            <p class="text--large" v-html="this.detail.text"></p>
                            <p class="text--large" v-if="this.detail.word !== undefined && this.detail.word !== ''">
                                <span>{{ this.detail.name }}</span> - {{ this.detail.place }}
                            </p>
                            <template v-if="this.detail.audio">
                                <iframe class="iframe-sound" width="100%" height="166" scrolling="no" frameborder="no" :src="`https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/${this.detail.audio}&color=%23f04529&auto_play=false&hide_related=true&show_comments=false&show_user=true&show_reposts=false&show_teaser=false`">
                                </iframe>
                            </template>
                            <!--<div style="font-size: 10px; color: #cccccc;line-break: anywhere;word-break: normal;overflow: hidden;white-space: nowrap;text-overflow: ellipsis; font-family: Interstate,Lucida Grande,Lucida Sans Unicode,Lucida Sans,Garuda,Verdana,Tahoma,sans-serif;font-weight: 100;">-->
                                <!--<a href="https://soundcloud.com/lilypad" title="lilypad" target="_blank" style="color: #cccccc; text-decoration: none;">lilypad</a> · <a href="https://soundcloud.com/lilypad/untitled" title="13 by C54" target="_blank" style="color: #cccccc; text-decoration: none;">13 by C54</a>-->
                            <!--</div>-->
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <a @click="goBack()" class="link-back">
            <span class="icon--overview">
                <font-awesome-icon :icon="['fas', 'th']" />
            </span>
            {{content.witnesses.back}}
        </a>
    </div>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';
import json from '../json/global.json';
// import fontawesome from "@fortawesome/fontawesome-pro";
import { library } from '@fortawesome/fontawesome-svg-core';
// # Note we are using the Pro style here
import { faTh } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

library.add(faTh);

@Options({
    components: {
        FontAwesomeIcon
    },
    data () {
        return {
            content: json,
            publicPath: process.env.BASE_URL
        };
    },
    methods: {
        getImageUrl (pic: string) {
            return this.publicPath + 'assets/' + pic;
        },
        goBack () {
            window.history.length > 1 ? this.$router.go(-1) : this.$router.push('/');
        }
    },
    props: {
        detail: Object
    }
})
export default class PhotoLayout extends Vue {}
</script>
